import dayjs from 'dayjs';
import { Instance, applySnapshot, types } from 'mobx-state-tree';

import { PILATES_QUIZ_STEPS_LIST } from '@features/Quiz/PilatesOnboarding/config';
import { TpPilatesQuizStepList } from '@features/Quiz/PilatesOnboarding/types';
import { HeightUnits, WeightUnits } from '@features/Quiz/constants';

export const QuizStoreModel = types
  .model('QuizStore')
  .props({
    country: types.maybe(types.string),
    countryCode: types.maybe(types.string),
    goal: types.maybe(types.string),
    motivation: types.maybe(types.string),
    currentBody: types.maybe(types.string),
    targetZone: types.maybe(types.string),
    dreamBody: types.maybe(types.string),
    bodyFocus: types.maybe(types.frozen(types.array(types.string))),
    perfectShape: types.maybe(types.string),
    breathAfterWalking: types.maybe(types.string),
    age: types.maybe(types.string),
    heightValue: types.maybe(types.number),
    heightUnits: types.maybe(
      types.enumeration<HeightUnits>(Object.values(HeightUnits)),
    ),
    weightValue: types.maybe(types.number),
    weightUnits: types.maybe(
      types.enumeration<WeightUnits>(Object.values(WeightUnits)),
    ),
    goalWeightValue: types.maybe(types.number),
    goalWeightUnits: types.maybe(
      types.enumeration<WeightUnits>(Object.values(WeightUnits)),
    ),
    currentBMI: types.maybe(types.number),
    weightPerDay: types.maybe(types.number),
    flexible: types.maybe(types.string),
    describeExperience: types.maybe(types.string),
    additionalGoals: types.maybe(types.string),
    perfectWorkout: types.maybe(types.string),
    oftenExercise: types.maybe(types.string),
    mealPrepDaily: types.maybe(types.string),
    anyDietary: types.maybe(types.frozen(types.array(types.string))),
    markVegetables: types.maybe(types.frozen(types.array(types.string))),
    markProducts: types.maybe(types.frozen(types.array(types.string))),
    markProteins: types.maybe(types.frozen(types.array(types.string))),
    sleep: types.maybe(types.string),
    waterIntake: types.maybe(types.string),
    habits: types.maybe(types.frozen(types.array(types.string))),
    scheduleLike: types.maybe(types.string),
    lifestyle: types.maybe(types.string),
    energyLevel: types.maybe(types.string),
    oftenWalks: types.maybe(types.string),
    importantEvents: types.maybe(types.string),
    whenEvent: types.maybeNull(types.Date),
    agePilates: types.maybe(types.string),
    goalPilates: types.maybe(types.string),
    physicalBuild: types.maybe(types.string),
    dreamBodyPilates: types.maybe(types.string),
    fitnessExperience: types.maybe(types.string),
    bestShape: types.maybe(types.string),
    triedPilates: types.maybe(types.string),
    flexiblePilates: types.maybe(types.string),
    exercise: types.maybe(types.string),
    outOfBreath: types.maybe(types.string),
    walks: types.maybe(types.string),
    targetZones: types.maybe(types.frozen(types.array(types.string))),
    struggleWith: types.maybe(types.frozen(types.array(types.string))),
    workSchedule: types.maybe(types.string),
    typicalDay: types.maybe(types.string),
    energyLevelPilates: types.maybe(types.string),
    usuallySleep: types.maybe(types.string),
    dailyDrinking: types.maybe(types.string),
    breakfastTime: types.maybe(types.string),
    lunchTime: types.maybe(types.string),
    dinnerTime: types.maybe(types.string),
    anyDiet: types.maybe(types.string),
    badHabits: types.maybe(types.frozen(types.array(types.string))),
    lifeEvents: types.maybe(types.frozen(types.array(types.string))),
    eventComing: types.maybe(types.string),
    ageNumber: types.maybe(types.number),
    userName: types.maybe(types.string),
  })
  .views((self) => ({
    getSingleOptionSelectStepValue: (step: TpPilatesQuizStepList) => {
      switch (step) {
        case PILATES_QUIZ_STEPS_LIST.WELCOME: {
          return self.agePilates;
        }
        case PILATES_QUIZ_STEPS_LIST.GOAL_PILATES: {
          return self.goalPilates;
        }
        case PILATES_QUIZ_STEPS_LIST.PHYSICAL_BUILD: {
          return self.physicalBuild;
        }
        case PILATES_QUIZ_STEPS_LIST.DREAM_BODY_PILATES: {
          return self.dreamBodyPilates;
        }
        case PILATES_QUIZ_STEPS_LIST.FITNESS_EXPERIENCE: {
          return self.fitnessExperience;
        }
        case PILATES_QUIZ_STEPS_LIST.BEST_SHAPE: {
          return self.bestShape;
        }
        case PILATES_QUIZ_STEPS_LIST.TRIED_PILATES: {
          return self.triedPilates;
        }
        case PILATES_QUIZ_STEPS_LIST.FLEXIBLE_PILATES: {
          return self.flexiblePilates;
        }
        case PILATES_QUIZ_STEPS_LIST.EXERCISE: {
          return self.exercise;
        }
        case PILATES_QUIZ_STEPS_LIST.OUT_OF_BREATH: {
          return self.outOfBreath;
        }
        case PILATES_QUIZ_STEPS_LIST.WALKS: {
          return self.walks;
        }
        case PILATES_QUIZ_STEPS_LIST.WORK_SCHEDULE: {
          return self.workSchedule;
        }
        case PILATES_QUIZ_STEPS_LIST.BREAKFAST_TIME: {
          return self.breakfastTime;
        }
        case PILATES_QUIZ_STEPS_LIST.LUNCH_TIME: {
          return self.lunchTime;
        }
        case PILATES_QUIZ_STEPS_LIST.DINNER_TIME: {
          return self.dinnerTime;
        }
        case PILATES_QUIZ_STEPS_LIST.ANY_DIET: {
          return self.anyDiet;
        }
        case PILATES_QUIZ_STEPS_LIST.TYPICAL_DAY: {
          return self.typicalDay;
        }
        case PILATES_QUIZ_STEPS_LIST.ENERGY_LEVEL: {
          return self.energyLevelPilates;
        }
        case PILATES_QUIZ_STEPS_LIST.USUALLY_SLEEP: {
          return self.usuallySleep;
        }
        case PILATES_QUIZ_STEPS_LIST.DAILY_DRINK: {
          return self.dailyDrinking;
        }
        case PILATES_QUIZ_STEPS_LIST.EVENT_COMING: {
          return self.eventComing;
        }
        default: {
          throw new Error('The step is not of type SINGLE_OPTION_SELECT');
        }
      }
    },
    getMultipleOptionsSelectStepValue: (step: TpPilatesQuizStepList) => {
      switch (step) {
        case PILATES_QUIZ_STEPS_LIST.TARGET_ZONES: {
          return self.targetZones;
        }
        case PILATES_QUIZ_STEPS_LIST.STRUGGLE_WITH: {
          return self.struggleWith;
        }
        case PILATES_QUIZ_STEPS_LIST.BAD_HABITS: {
          return self.badHabits;
        }
        case PILATES_QUIZ_STEPS_LIST.LIFE_EVENTS: {
          return self.lifeEvents;
        }
        default: {
          throw new Error('The step is not of type MULTIPLE_OPTIONS_SELECT');
        }
      }
    },
  }))
  .views((self) => ({
    get goalCompleted() {
      return self.goal != null;
    },
    get motivationCompleted() {
      return self.motivation != null;
    },
    get currentBodyCompleted() {
      return self.currentBody != null;
    },
    get targetZoneCompleted() {
      return self.targetZone != null;
    },
    get dreamBodyCompleted() {
      return self.dreamBody != null;
    },
    get bodyFocusCompleted() {
      return self.bodyFocus != null && self.bodyFocus.length > 0;
    },
    get perfectShapeCompleted() {
      return self.perfectShape != null;
    },
    get breathAfterWalkingCompleted() {
      return self.breathAfterWalking != null;
    },
    get ageCompleted() {
      return self.age != null;
    },
    get heightCompleted() {
      return self.heightValue != null;
    },
    get currentBMICompleted() {
      return self.currentBMI != null;
    },
    get weightCompleted() {
      return self.weightValue != null;
    },
    get goalWeightCompleted() {
      return self.goalWeightValue != null;
    },
    get flexibleCompleted() {
      return self.flexible != null;
    },
    get describeExperienceCompleted() {
      return self.describeExperience != null;
    },
    get additionalGoalsCompleted() {
      return self.additionalGoals != null;
    },
    get perfectWorkoutCompleted() {
      return self.perfectWorkout != null;
    },
    get oftenExerciseCompleted() {
      return self.oftenExercise != null;
    },
    get mealPrepDailyCompleted() {
      return self.mealPrepDaily != null;
    },
    get anyDietaryCompleted() {
      return self.anyDietary != null;
    },
    get markVegetablesCompleted() {
      return self.markVegetables != null;
    },
    get markProductsCompleted() {
      return self.markProducts != null;
    },
    get markProteinsCompleted() {
      return self.markProteins != null;
    },
    get sleepCompleted() {
      return self.sleep != null;
    },
    get waterIntakeCompleted() {
      return self.waterIntake != null;
    },
    get habitsCompleted() {
      return self.habits != null && self.habits.length > 0;
    },
    get agePilatesCompleted() {
      return self.agePilates != null;
    },
    get scheduleLikeCompleted() {
      return self.scheduleLike != null;
    },
    get lifestyleCompleted() {
      return self.lifestyle != null;
    },
    get energyLevelCompleted() {
      return self.energyLevel != null;
    },
    get oftenWalksCompleted() {
      return self.oftenWalks != null;
    },
    get importantEventsCompleted() {
      return self.importantEvents != null;
    },
    get whenEventCompleted() {
      return self.whenEvent !== undefined;
    },
    get goalPilatesCompleted() {
      return self.goalPilates != null;
    },
    get physicalBuildCompleted() {
      return self.physicalBuild != null;
    },
    get dreamBodyPilatesCompleted() {
      return self.dreamBodyPilates != null;
    },
    get fitnessExperienceCompleted() {
      return self.fitnessExperience != null;
    },
    get bestShapeCompleted() {
      return self.bestShape != null;
    },
    get triedPilatesCompleted() {
      return self.triedPilates != null;
    },
    get flexiblePilatesCompletes() {
      return self.flexiblePilates != null;
    },
    get exerciseCompleted() {
      return self.exercise != null;
    },
    get outOfBreathCompleted() {
      return self.outOfBreath != null;
    },
    get walksCompleted() {
      return self.walks != null;
    },
    get targetZonesCompleted() {
      return self.targetZones != null && self.targetZones.length > 0;
    },
    get struggleWithCompleted() {
      return self.struggleWith != null && self.struggleWith.length > 0;
    },
    get meaningGoal() {
      switch (self.goal) {
        case 'Weight loss': {
          return 0;
        }
        case 'Bikini body': {
          return 1;
        }
        case 'Postpartum recovery': {
          return 2;
        }
        default: {
          return 0;
        }
      }
    },
    get workScheduleCompleted() {
      return self.workSchedule != null;
    },
    get typicalDayCompleted() {
      return self.typicalDay != null;
    },
    get energyLevelPilatesCompleted() {
      return self.energyLevelPilates != null;
    },
    get usuallySleepCompleted() {
      return self.usuallySleep != null;
    },
    get dailyDrinkingCompleted() {
      return self.dailyDrinking != null;
    },
    get breakfastTimeCompleted() {
      return self.breakfastTime != null;
    },
    get ageNumberCompleted() {
      return self.ageNumber != null;
    },
    get userNameCompleted() {
      return self.userName != null;
    },
    get lunchTimeCompleted() {
      return self.lunchTime != null;
    },
    get dinnerTimeCompleted() {
      return self.dinnerTime != null;
    },
    get anyDietCompleted() {
      return self.anyDiet != null;
    },
    get badHabitsCompleted() {
      return self.badHabits != null && self.badHabits.length > 0;
    },
    get lifeEventsCompleted() {
      return self.lifeEvents != null && self.lifeEvents.length > 0;
    },
    get eventComingCompleted() {
      return self.eventComing != null;
    },
  }))
  .views((self) => ({
    get allPilatesQuizStepsCompleted() {
      return (
        self.agePilatesCompleted &&
        self.goalPilatesCompleted &&
        self.physicalBuildCompleted &&
        self.dreamBodyPilatesCompleted &&
        self.fitnessExperienceCompleted &&
        self.bestShapeCompleted &&
        self.triedPilatesCompleted &&
        self.flexiblePilatesCompletes &&
        self.exerciseCompleted &&
        self.outOfBreathCompleted &&
        self.walksCompleted &&
        self.targetZonesCompleted &&
        self.struggleWithCompleted &&
        self.typicalDayCompleted &&
        self.energyLevelPilatesCompleted &&
        self.usuallySleepCompleted &&
        self.dailyDrinkingCompleted &&
        self.lunchTimeCompleted &&
        self.dinnerTimeCompleted &&
        self.anyDietCompleted &&
        self.workScheduleCompleted &&
        self.breakfastTimeCompleted &&
        self.lifeEventsCompleted &&
        self.badHabitsCompleted &&
        self.eventComingCompleted &&
        self.workScheduleCompleted &&
        self.breakfastTimeCompleted &&
        self.ageNumberCompleted &&
        self.heightCompleted &&
        self.weightCompleted &&
        self.userNameCompleted
      );
    },
  }))
  .actions((self) => ({
    setCountry: (value: string) => {
      self.country = value;
    },
    setCountryCode: (value: string) => {
      self.countryCode = value;
    },
    setGoal: (value: string) => {
      self.goal = value;
    },
    setMotivation: (value: string) => {
      self.motivation = value;
    },
    setCurrentBody: (value: string) => {
      self.currentBody = value;
    },
    setTargetZone: (value: string) => {
      self.targetZone = value;
    },
    setDreamBody: (value: string) => {
      self.dreamBody = value;
    },
    setBodyFocus: (value?: readonly string[] | null) => {
      self.bodyFocus = value == null ? [] : value;
    },
    setPerfectShape: (value: string) => {
      self.perfectShape = value;
    },
    setBreathAfterWalking: (value: string) => {
      self.breathAfterWalking = value;
    },
    setAge: (value: string) => {
      self.age = value;
    },
    setHeight: (value: number, units: HeightUnits) => {
      self.heightValue = value;
      self.heightUnits = units;
      if (self.weightUnits == null || self.goalWeightUnits == null) {
        let weightUnits: WeightUnits;
        switch (units) {
          case HeightUnits.FEET: {
            weightUnits = WeightUnits.LBS;
            break;
          }
          case HeightUnits.CM: {
            weightUnits = WeightUnits.KG;
            break;
          }
        }
        self.weightUnits ??= weightUnits;
        self.goalWeightUnits ??= weightUnits;
      }
    },
    setWeight: (value: number, units: WeightUnits) => {
      self.weightValue = value;
      self.weightUnits = units;
    },
    setGoalWeight: (value: number, units: WeightUnits) => {
      self.goalWeightValue = value;
      self.goalWeightUnits = units;
    },
    setCurrentBMI: (value: number) => {
      self.currentBMI = value;
    },
    setWeightPerDay: (value: number) => {
      self.weightPerDay = value;
    },
    setFlexible: (value: string) => {
      self.flexible = value;
    },
    setDescribeExperience: (value: string) => {
      self.describeExperience = value;
    },
    setAdditionalGoals: (value: string) => {
      self.additionalGoals = value;
    },
    setPerfectWorkout: (value: string) => {
      self.perfectWorkout = value;
    },
    setOftenExercise: (value: string) => {
      self.oftenExercise = value;
    },
    setMealPrepDaily: (value: string) => {
      self.mealPrepDaily = value;
    },
    setAnyDietary: (value?: readonly string[] | null) => {
      self.anyDietary = value == null ? [] : value;
    },
    setMarkVegetables: (value?: readonly string[] | null) => {
      self.markVegetables = value == null ? [] : value;
    },
    setMarkProducts: (value?: readonly string[] | null) => {
      self.markProducts = value == null ? [] : value;
    },
    setMarkProteins: (value?: readonly string[] | null) => {
      self.markProteins = value == null ? [] : value;
    },
    setSleep: (value: string) => {
      self.sleep = value;
    },
    setWaterIntake: (value: string) => {
      self.waterIntake = value;
    },
    setHabits: (value?: readonly string[] | null) => {
      self.habits = value == null ? [] : value;
    },
    setAgePilates: (value: string) => {
      self.agePilates = value;
    },
    setScheduleLike: (value: string) => {
      self.scheduleLike = value;
    },
    setLifestyle: (value: string) => {
      self.lifestyle = value;
    },
    setEnergyLevel: (value: string) => {
      self.energyLevel = value;
    },
    setOftenWalks: (value: string) => {
      self.oftenWalks = value;
    },
    setImportantEvents: (value: string) => {
      self.importantEvents = value;
    },
    setWhenEvent: (value: Date | null) => {
      self.whenEvent = value;
    },
    setGoalPilates: (value: string) => {
      self.goalPilates = value;
    },
    setPhysicalBuild: (value: string) => {
      self.physicalBuild = value;
    },
    setDreamBodyPilates: (value: string) => {
      self.dreamBodyPilates = value;
    },
    setFitnessExperience: (value: string) => {
      self.fitnessExperience = value;
    },
    setBestShape: (value: string) => {
      self.bestShape = value;
    },
    setTriedPilates: (value: string) => {
      self.triedPilates = value;
    },
    setFlexiblePilates: (value: string) => {
      self.flexiblePilates = value;
    },
    setExercise: (value: string) => {
      self.exercise = value;
    },
    setOutOfBreath: (value: string) => {
      self.outOfBreath = value;
    },
    setWalks: (value: string) => {
      self.walks = value;
    },
    setTargetZones: (value?: readonly string[] | null) => {
      self.targetZones = value == null ? [] : value;
    },
    setStruggleWith: (value?: readonly string[] | null) => {
      self.struggleWith = value == null ? [] : value;
    },
    setWorkSchedule: (value: string) => {
      self.workSchedule = value;
    },
    setBreakfastTime: (value: string) => {
      self.breakfastTime = value;
    },
    setAgeNumber: (value: number) => {
      self.ageNumber = value;
    },
    setUserName: (value: string) => {
      self.userName = value;
    },
    setLunchTime: (value: string) => {
      self.lunchTime = value;
    },
    setDinnerTime: (value: string) => {
      self.dinnerTime = value;
    },
    setAnyDiet: (value: string) => {
      self.anyDiet = value;
    },
    setTypicalDay: (value: string) => {
      self.typicalDay = value;
    },
    setEnergyLevelPilates: (value: string) => {
      self.energyLevelPilates = value;
    },
    setUsuallySleep: (value: string) => {
      self.usuallySleep = value;
    },
    setDailyDrinking: (value: string) => {
      self.dailyDrinking = value;
    },
    setBadHabits: (value?: readonly string[] | null) => {
      self.badHabits = value == null ? [] : value;
    },
    setLifeEvents: (value?: readonly string[] | null) => {
      self.lifeEvents = value == null ? [] : value;
    },
    setEventComing: (value: string) => {
      self.eventComing = value;
    },
    fillInAllSteps: () => {
      if (!self.goalCompleted) {
        self.goal = 'Weight loss';
      }
      if (!self.motivationCompleted) {
        self.motivation = 'find_love';
      }
      if (!self.currentBodyCompleted) {
        self.currentBody = 'average';
      }
      if (!self.targetZoneCompleted) {
        self.targetZone = 'toned_butt';
      }
      if (!self.dreamBodyCompleted) {
        self.dreamBody = 'fit';
      }
      if (!self.bodyFocusCompleted) {
        self.bodyFocus = ['weight_lose'];
      }
      if (!self.perfectShapeCompleted) {
        self.perfectShape = 'less_than_a_year_ago';
      }
      if (!self.breathAfterWalkingCompleted) {
        self.breathAfterWalking = 'i’m_so_out_of_breath_that_i_can’t_talk';
      }
      if (!self.ageCompleted) {
        self.age = '18_29';
      }
      if (!self.currentBMICompleted) {
        self.currentBMI = 25;
      }
      if (!self.heightCompleted) {
        self.heightValue = 170;
        self.heightUnits = HeightUnits.CM;
      }
      if (!self.weightCompleted) {
        self.weightValue = 80;
        self.weightUnits = WeightUnits.KG;
      }
      if (!self.goalWeightCompleted) {
        self.goalWeightValue = 65;
        self.goalWeightUnits = WeightUnits.KG;
      }
      if (!self.flexibleCompleted) {
        self.flexible = 'pretty_flexible';
      }
      if (!self.describeExperienceCompleted) {
        self.describeExperience = 'i_have_trouble_gaining_muscle_or_body_fat';
      }
      if (!self.additionalGoalsCompleted) {
        self.additionalGoals = 'reduce_neck_pain';
      }
      if (!self.perfectWorkoutCompleted) {
        self.perfectWorkout = 'slow_full_body_workout';
      }
      if (!self.oftenExerciseCompleted) {
        self.oftenExercise = 'several_times_per_week';
      }
      if (!self.mealPrepDailyCompleted) {
        self.mealPrepDaily = 'less_than_30_min';
      }
      if (!self.anyDietaryCompleted) {
        self.anyDietary = ['low_carb_or_keto'];
      }
      if (!self.markVegetablesCompleted) {
        self.markVegetables = ['broccoli'];
      }
      if (!self.markProductsCompleted) {
        self.markProducts = ['yogurt'];
      }
      if (!self.markProteinsCompleted) {
        self.markProteins = ['chicken'];
      }
      if (!self.sleepCompleted) {
        self.sleep = 'less_than_6_hours';
      }
      if (!self.waterIntakeCompleted) {
        self.waterIntake = 'i_drink_only_coffee_or_tea';
      }
      if (!self.habitsCompleted) {
        self.habits = ['eating_late_at_night'];
      }
      if (!self.scheduleLikeCompleted) {
        self.scheduleLike = '9_to_5';
      }
      if (!self.agePilatesCompleted) {
        self.agePilates = '18_35';
      }
      if (!self.lifestyleCompleted) {
        self.lifestyle = 'at_home_mostly_sedentary';
      }
      if (!self.energyLevelCompleted) {
        self.energyLevel = "it's_fine_and_stable";
      }
      if (!self.oftenWalksCompleted) {
        self.oftenWalks = 'almost_every_day';
      }
      if (!self.importantEventsCompleted) {
        self.importantEvents = 'wedding';
      }
      if (!self.whenEventCompleted) {
        self.whenEvent = dayjs().toDate();
      }
      if (!self.goalPilatesCompleted) {
        self.goalPilates = 'lose_weight';
      }
      if (!self.physicalBuildCompleted) {
        self.physicalBuild = 'slim';
      }
      if (!self.dreamBodyPilatesCompleted) {
        self.dreamBodyPilates = 'thin';
      }
      if (!self.fitnessExperienceCompleted) {
        self.fitnessExperience = 'trouble_gaining_muscle';
      }
      if (!self.bestShapeCompleted) {
        self.bestShape = 'less_than_a_year_ago';
      }
      if (!self.triedPilatesCompleted) {
        self.triedPilates = 'regularly';
      }
      if (!self.flexiblePilatesCompletes) {
        self.flexiblePilates = 'pretty_flexible';
      }
      if (!self.exerciseCompleted) {
        self.exercise = 'almost_every_day';
      }
      if (!self.outOfBreathCompleted) {
        self.outOfBreath = 'can_not_talk';
      }
      if (!self.walksCompleted) {
        self.walks = 'almost_every_day';
      }
      if (!self.targetZonesCompleted) {
        self.targetZones = ['flat_belly'];
      }
      if (!self.struggleWithCompleted) {
        self.struggleWith = ['sensitive_back'];
      }
      if (!self.workScheduleCompleted) {
        self.workSchedule = '9_to_5';
      }
      if (!self.breakfastTimeCompleted) {
        self.breakfastTime = 'between_6_and_8_am';
      }
      if (!self.ageNumberCompleted) {
        self.ageNumber = 30;
      }
      if (!self.userNameCompleted) {
        self.userName = 'Vadim';
      }
      if (!self.lunchTimeCompleted) {
        self.lunchTime = 'between_10_am_and_noon';
      }
      if (!self.dinnerTimeCompleted) {
        self.dinnerTime = 'between_4_and_6_pm';
      }
      if (!self.anyDietCompleted) {
        self.anyDiet = 'keto';
      }
      if (!self.typicalDayCompleted) {
        self.typicalDay = 'sitting';
      }
      if (!self.energyLevelPilatesCompleted) {
        self.energyLevelPilates = 'balanced_and_steady';
      }
      if (!self.usuallySleepCompleted) {
        self.usuallySleep = 'less_than_5_hours';
      }
      if (!self.dailyDrinkingCompleted) {
        self.dailyDrinking = 'only_coffee_or_tea';
      }
      if (!self.badHabitsCompleted) {
        self.badHabits = ['eat_late_at_night'];
      }
      if (!self.lifeEventsCompleted) {
        self.struggleWith = ['marriage_or_relationship'];
      }
      if (!self.eventComingCompleted) {
        self.eventComing = 'wedding';
      }
    },
  }))
  .actions((self) => ({
    reset: () => {
      applySnapshot(self, {});
    },
  }));

type QuizStoreType = Instance<typeof QuizStoreModel>;

export interface QuizStore extends QuizStoreType {}
