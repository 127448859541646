import classNames from 'classnames';
import ImageTag from 'next/image';
import type { ImageProps } from 'next/image';

import styles from './Image.module.scss';

export type TpImageProps = ImageProps & {
  className?: string;
};

// export const imgSource = '/images/';
export const imgSource =
  process.env.NEXT_PUBLIC_ENV === 'development'
    ? 'https://du0zh04rgghb5.cloudfront.net/images/'
    : 'https://d3sh2w8qq2e7ze.cloudfront.net/images/';
const customLoader = ({ src }: { src: string }) => `${imgSource}${src}`;

export const Image = (props: TpImageProps) => {
  const { src, className, ...otherProps } = props;

  return (
    <span className={classNames(styles.container, className)}>
      <ImageTag src={src} loader={customLoader} {...otherProps} />
    </span>
  );
};
