import 'react-loading-skeleton/dist/skeleton.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../styles/index.scss'
import '../styles/themes-tokens.css';
import '@mentalgrowth/ui-kit-web/dist/cjs/styles.css';

import type { AppProps } from 'next/app'
import { RootStoreProvider } from '../components/common/root-store-provider/root-store-provider'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import * as yup from 'yup'
import { ResizeObserver } from '@juggle/resize-observer'
import Head from 'next/head'
import { AnalyticsInject, AnalyticsDebuggerProvider } from '@features/Analytics'
import { DehydratedState, HydrationBoundary, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { IntlProvider } from '@features/intl';
import { ThemeProvider } from "@features/Theme";
import { ErrorBoundary } from "src/components/common/ErrorBoundary";
import * as Sentry from '@sentry/react';
import {Inter, Merriweather} from 'next/font/google';

// Enable Day.js extensions
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)

const inter = Inter({
  weight: ['400', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: "--font-inter",
});

const merriweather = Merriweather({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: "--font-merriweather",
});

// Customize yup error formatting
const formatError = yup.ValidationError.formatError
yup.ValidationError.formatError = function (message, parameters) {
  if (!parameters.label) {
    parameters.label = (parameters.path + '')
      .replaceAll(
        /([a-z])([A-Z])/g,
        (_, match1, match2) => `${match1} ${match2.toLowerCase()}`
      )
      .replace(/^[a-z]/, (match) => match.toUpperCase())
  }
  return formatError.call(this, message, parameters)
}

// Polyfill ResizeObserver
if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

function MyApp({ Component, pageProps }: AppProps & {
    pageProps: {
      dehydratedState: DehydratedState
    }
  }) {

  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30_000),
      },
    },
    queryCache:  new QueryCache({
      onError: (error) => {
        console.log(error);
        Sentry.captureException(error);
      },
    }),
  }));

  return (
    <>
      <Head>
        <title>Youth</title>
        <meta charSet="utf8"/>
        <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
      </Head>
      <style jsx global>{`
          html {
              --font-inter: ${inter.style.fontFamily};
              --font-merriweather: ${merriweather.style.fontFamily};
              --font-family-base: var(--font-inter);
              --font-family-secondary: var(--font-merriweather);
          }
      `}</style>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <HydrationBoundary state={pageProps.dehydratedState}>
              <AnalyticsDebuggerProvider>
                <RootStoreProvider>
                  <ThemeProvider>
                    <Component {...pageProps} />
                    <AnalyticsInject/>
                  </ThemeProvider>
                </RootStoreProvider>
              </AnalyticsDebuggerProvider>
            </HydrationBoundary>
          </ErrorBoundary>
        </QueryClientProvider>
      </IntlProvider>
    </>
  )
}

export default MyApp
