import {
  Instance,
  SnapshotOut,
  applySnapshot,
  getRoot,
  types,
} from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

import { getBMI } from 'src/utils/utils-health';

import { HeightUnits } from '@features/Quiz/constants';

import * as auth from '../../api/auth';
import { RootStoreModel } from '../root-store/root-store';
import { UserModel, UserSnapshot } from '../user/user';

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    user: types.maybe(types.union(UserModel, types.null)),
    auth_token: types.maybe(types.string),
    access_code: types.maybe(types.string),
    googlePseudoID: types.maybe(types.string),
    consentCookie: false,
    loginedByWinback: false,
    variant: types.maybe(types.string),
  })
  .actions((self) => ({
    setUser: (user: UserSnapshot | null) => {
      if (!self.user || user === null) {
        self.user = user;
      } else {
        applySnapshot(self.user, user);
      }
    },
    setAuthToken: (token: string) => {
      self.auth_token = token;
    },
    setAccessCode: (code: string) => {
      self.access_code = code;
    },
    setGooglePseudoID: (code: string) => {
      self.googlePseudoID = code;
    },
    setConsentCookie: (value: boolean) => {
      self.consentCookie = value;
    },
    setLoginedByWinback: (value: boolean) => {
      self.loginedByWinback = value;
    },
    setVariant: (variant: string | undefined) => {
      self.variant = variant;
    },
  }))
  .actions((self) => ({
    fetchUser: flow(function* ({
      token,
      abortSignal,
    }: {
      token?: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.fetchUser({ token, abortSignal });
      self.setUser(user);
    }),
  }))
  .actions((self) => ({
    register: flow(function* ({
      email,
    }: {
      email: string;
    }): Generator<PromiseLike<any>, void, any> {
      const { quizStore } = getRoot<typeof RootStoreModel>(self);
      const user = yield auth.register({
        email,
        goal: quizStore.meaningGoal!,
        quizHeightUnits: quizStore.heightUnits!,
        quizHeightValue: quizStore.heightValue!,
        quizWeightUnits: quizStore.weightUnits!,
        quizWeightValue: quizStore.weightValue!,
        quizGoalWeightUnits: quizStore.goalWeightUnits!,
        quizGoalWeightValue: quizStore.goalWeightValue!,
        contentType: 'pilates',
        variant: self.variant,
        userQuizData: quizStore,
      });
      self.setAuthToken(user.token);
      self.setAccessCode(user.accessCode);
    }),
    logOut: flow(function* () {
      yield auth.logOut();
      self.setUser(null);
      self.setAuthToken('');
      self.setAccessCode('');
      self.setGooglePseudoID('');
      self.setLoginedByWinback(false);
    }),
    loginBySafariAuthToken: flow(function* ({
      safariAuthToken,
    }: {
      safariAuthToken: string;
    }) {
      const user = yield auth.loginBySafariAuthToken({ safariAuthToken });
      self.setUser(user);
      self.setAccessCode(user.accessCode);
      self.setAuthToken(user.token);
    }),
    loginByEmailToken: flow(function* ({
      emailToken,
      abortSignal,
    }: {
      emailToken: string;
      abortSignal?: AbortSignal;
    }) {
      const user = yield auth.loginWithEmailToken({ emailToken, abortSignal });
      self.setUser(user);
      self.setAuthToken(user.token);
    }),
  }))
  .views((self) => ({
    get userBMI() {
      if (
        self.user?.weight_units == undefined ||
        self.user?.weight_value == undefined ||
        self.user?.height_units == undefined ||
        self.user?.height_value == undefined
      ) {
        return null;
      }
      return getBMI({
        weightUnits: self.user?.weight_units,
        weightValue: self.user?.weight_value,
        heightUnits: self.user?.height_units as HeightUnits,
        heightValue: self.user?.height_value,
      });
    },
    get meaningGoal() {
      if (!self.user) {
        return null;
      }

      switch (self.user.goal) {
        case 0: {
          return 'Weight loss';
        }
        case 1: {
          return 'Bikini body';
        }
        case 2: {
          return 'Postpartum recovery';
        }
        default: {
          return 'Weight loss';
        }
      }
    },
    get isStripeVariant() {
      return true;
      // return (
      //   self.variant === 'default' ||
      //   self.variant === 'variant16' ||
      //   self.variant === 'Pilates' ||
      //   self.variant === 'PilatesGoogle' ||
      //   self.variant === 'PilatesVideo' ||
      //   self.variant === 'PilatesPT' ||
      //   self.variant === 'test' ||
      //   self.variant === 'PilatesTikTok' ||
      //   self.variant === undefined
      // );
    },
  }));

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
