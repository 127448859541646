import type { ModalHandle } from '@restart/ui/Modal';
import classNames from 'classnames';
import React, { useRef } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

import styles from './modal-container.module.scss';

export type Props = ModalProps;

const ModalContainer = ({
  className,
  backdropClassName,
  dialogClassName,
  ...props
}: Props) => {
  const modalRef = useRef<ModalHandle>(null);

  return (
    <Modal
      ref={modalRef}
      backdropClassName={classNames(styles.backdrop, backdropClassName)}
      className={classNames(styles.container, className)}
      dialogClassName={classNames(styles.modal, dialogClassName)}
      restoreFocusOptions={{ preventScroll: true }}
      {...props}
    />
  );
};

export default ModalContainer;
